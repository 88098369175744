import type { RawWidgetConfigFragment } from '../gql/fragments/__generated/RawWidgetConfig';
import type { AppearanceFilter } from '../gql/schema';
import type { Nullable } from '../models/CustomUtilityTypes';

export default (
  widgetConfig: MaybeRef<Nullable<RawWidgetConfigFragment>>
): Ref<AppearanceFilter | null> => {
  return computed(() => {
    const dataValue = toValue(widgetConfig);

    if (dataValue?.deliveryChannel?.id) {
      return {
        deliveryChannel: dataValue.deliveryChannel.id,
      };
    }
    return null;
  });
};
