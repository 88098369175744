import type { Nullable } from '../models/CustomUtilityTypes';

/**
 * Checks if a data value is empty.
 *
 * A value is considered empty if it is:
 * - `null` or `undefined`
 * - An empty string (after trimming whitespace)
 * - An empty object (no own properties)
 * - An empty array
 * - An array where every item is empty
 *
 * @param data - The value or an array of values to check for emptiness. It can be of any type.
 * @returns `true` if the value is empty, `false` otherwise.
 */
export default function isEmpty<T>(
  data: MaybeRefOrGetter<Nullable<T> | Nullable<T>[] | T | T[]>,
  checkFor: 'some' | 'every' = 'every'
): boolean {
  const dataValue = toValue(data);

  // Handle null or undefined
  if (dataValue == null) {
    return true;
  }

  // Handle arrays
  if (Array.isArray(dataValue)) {
    return (
      dataValue.length === 0 ||
      (checkFor === 'some'
        ? dataValue.some((item) => isEmpty(item, checkFor))
        : dataValue.every((item) => isEmpty(item)))
    );
  }

  // Handle strings
  if (typeof dataValue === 'string') {
    return dataValue.trim().length === 0;
  }

  // Handle objects
  if (typeof dataValue === 'object') {
    if (dataValue instanceof Date) {
      return isNaN(dataValue.getTime()); // Invalid dates are considered empty
    }

    // Check if the object has no own properties or contains only empty values
    return (
      Object.keys(dataValue).length === 0 ||
      (checkFor === 'some'
        ? Object.values(dataValue).some((value) => isEmpty(value, checkFor))
        : Object.values(dataValue).every((value) => isEmpty(value)))
    );
  }

  // Handle primitive types like numbers and booleans
  return false;
}
