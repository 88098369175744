import type { WidgetConfigTypeDefs } from '../gql/schema';

export type GlobalStore = {
  modalState: 'open' | 'close';
  pageSize: number;
  listType: 'list' | 'gallery';
  bookmarksCount: number;
};

export const useGlobalStore = defineStore('globalStore', () => {
  const state = reactive({
    modalState: 'close' as 'open' | 'close',
    pageSize: 10,
    listType: '' as GlobalStore['listType'],
    bookmarksCount: 0,
  });

  const bookmarks = useLocalStorage<string[]>('bookmarks', []);

  onMounted(() => {
    state.bookmarksCount = bookmarks.value.length;
  });

  watchEffect(() => {
    state.bookmarksCount = bookmarks.value.length;
  });

  const addBookmark = (bookmark: string) => {
    if (!bookmarks.value.includes(bookmark)) {
      bookmarks.value.push(bookmark);
    }
  };

  const removeBookmark = (bookmark: string) => {
    bookmarks.value = bookmarks.value.filter((b) => b !== bookmark);
  };

  const setModalState = (newState: 'open' | 'close') => {
    state.modalState = newState;
  };

  const toggleModalState = () => {
    state.modalState = state.modalState === 'open' ? 'close' : 'open';
  };

  const increasePageSize = (size: number) => {
    if (!Number.isInteger(size) || size <= 0) {
      console.error('Size must be a positive integer');
      return;
    }
    state.pageSize += size;
  };

  const resetPageSize = () => {
    state.pageSize = 10;
  };

  const setListType = (
    widgetTypeConfig: WidgetConfigTypeDefs | null = null,
    type?: GlobalStore['listType']
  ) => {
    if (!type && !widgetTypeConfig) {
      return;
    }

    if (widgetTypeConfig && isEmpty(state.listType)) {
      state.listType = widgetTypeConfig.entryViewType?.name as
        | 'list'
        | 'gallery';
    } else if (!isEmpty(type)) {
      state.listType = type!;
    }
  };

  const toggleListType = () => {
    state.listType = state.listType === 'list' ? 'gallery' : 'list';
  };

  return {
    state,
    setModalState,
    toggleModalState,
    increasePageSize,
    resetPageSize,
    setListType,
    toggleListType,
    addBookmark,
    removeBookmark,
  };
});
