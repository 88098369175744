import { type ImgProxyService, ImgProxyServiceKey } from '@imx/imgproxy-vue';

export default () => {
  const imgProxyService: ImgProxyService | undefined =
    inject(ImgProxyServiceKey);
  if (!imgProxyService) {
    throw new Error('ImgProxyService is not available');
  }

  return {
    imgProxyService,
    imgproxy: (
      source: string,
      size: string,
      focalX?: number,
      focalY?: number,
      cacheBuster?: string
    ): string => {
      return imgProxyService.buildImageUrl(
        source,
        size,
        focalX,
        focalY,
        cacheBuster
      );
    },
  };
};
