<template>
  <figcaption
    class="caption"
    :class="{ 'caption--active': isActive }"
    @click="isActive = !isActive"
  >
    <BaseIcon name="mdi:copyright" class="icon" />
    <span class="text">{{ content }}</span>
  </figcaption>
</template>

<script lang="ts" setup>
const isActive = ref(false);

defineProps<{
  content: string;
}>();
</script>

<style src="./Caption.scss" scoped lang="scss"></style>
