import type { Nullable } from '../models/CustomUtilityTypes';

export default function joinNonEmptyStrings(
  values: Nullable<(string | null | undefined)[]>,
  separator: string = ' '
): string {
  if (isEmpty(values)) {
    return '';
  }

  return (values as string[])
    .filter((value) => typeof value === 'string' && !isEmpty(value))
    .map((value) => value?.trim())
    .join(separator);
}
